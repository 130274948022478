<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2024-07-09 16:39:02
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-09-24 14:15:19
-->
<template>
    <div class="setupDocument">
        <div class="sm-title">
            <div class="mt-title">每日一言</div>
        </div>
        <div class="conetnt-wrap">
            <div class="flex-sbc" style="width:100%; padding-bottom: 20px">
                <div class="flex-wc" >
                    <el-input v-model="keyworld" placeholder="请输入搜索内容" style="width: 300px;margin-right: 20px"></el-input>
                    <el-button @click="clickSearch" type="primary" >搜索</el-button>
                </div>
                <el-button @click="clickAdd" type="primary" >添加每日一言</el-button>
            </div>
            <el-table
                :data="tableData"
                style="width: 100%;"
                border
                >
                <el-table-column align="center" prop="id" label="ID" width="100"></el-table-column>
                <el-table-column align="center" label="每日一言背景图">
                    <template slot-scope="scope">
                        <img height="50px" :src="scope.row.img" alt="">
                    </template>
                </el-table-column>
                <el-table-column align="center" label="每日一言文案">
                    <template slot-scope="scope">
                        <div>
                            <div>{{scope.row.content}}</div>
                            <div class="flex-fec">—— {{scope.row.author}}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="180">
                    <template slot-scope="scope">
                        <div class="flex-cc">
                            <div @click="clickEdit(scope.row)" style="padding: 0 15px">
                                <i class="el-icon-edit" style="font-size: 20px"></i>
                            </div>
                            <div @click="clickDel(scope.row)" style="padding: 0 15px">
                                <i class="el-icon-delete" style="font-size: 20px"></i>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-wrap">
            <div class="pagination-wrap">
                <el-pagination
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    :current-page.sync="page_num"
                    :page-sizes="[20, 50, 100, 200]"
                    :page-size="page_size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog
            :visible.sync="isBigShow"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="700px">
            <div class="class-wrap">
                <div class="cw-title">{{rowInfo.id?'编辑内容':'上传每日一言素材'}}</div>
                <div class="cw-list">
                    <div class="item flex-sb">
                        <div class="tip flex-wc">每日一言文案
                            <div style="color: #ff0000">*</div>
                        </div>
                        <div class="con">
                            <el-input :maxlength="63" type="textarea" 
                                v-model="info.content" placeholder="每日一言文案"></el-input>
                        </div>
                    </div>
                    <div class="item flex-sbc">
                        <div class="tip">每日一言作者</div>
                        <div class="con">
                            <el-input v-model="info.author" placeholder="每日一言作者"></el-input>
                        </div>
                    </div>
                    <div class="item flex-sb">
                        <div class="tip flex-wc">背景图片
                            <div style="color: #ff0000">*</div>
                        </div>
                        <div class="con">
                            <el-upload
                                action="https://api.ukoocloud.com/service/service/wechat_qr_code_up"
                                :headers="headers"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                >
                                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </div>
                    </div>
                </div>
                <div class="btn-wrap">
                    <div class="cw-btn quxiao" @click="clickClose">取消</div>
                    <div v-if="rowInfo.id" class="cw-btn" @click="clickSave">保存</div>
                    <div v-else class="cw-btn" @click="clickAddSave">保存</div>
                    <!-- <div v-else>
                        <div v-if="rowInfo.id" class="cw-btn" @click="clickSave">保存</div>
                        <div v-else class="cw-btn" @click="clickAddMain">确定</div>
                    </div> -->
                </div>
            </div>
        </el-dialog>
        <el-dialog
            :visible.sync="isDel"
            width="500px"
            :show-close="false">
            <div class="channel-wrap">
                <div class="cw-title">确定删除改文案内容?</div>
                <div class="cw-btn">
                    <div class="btn cancel" @click="isDel = false">取消</div>
                    <div class="btn confirm" @click="clickConfirm">确定</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import api from "@/utils/common.js"
import pageMixin from '@/internal/pageMixin'

export default {
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'dailyOne',
            tableData: [],
            isBigShow: false,
            isDel: false,
            info: {
                content: '',
                author: '',
                img: '',
            },
            rowInfo: {},
            miniId: '',

            keyworld: '',
            page_num: 1,
            page_size: 20,
            total: 0,
            headers: {},
            imageUrl: '',
        }
    },
    mounted () {
        let token = localStorage.getItem("token");
        var authorization = 'Bearer ' + token
        let headers = {
            'Authorization': authorization
        }
        this.headers = headers;
        this.getHelpList()
    },
    methods: {
        handleCurrentChange(val) {
            this.page_num = val;
            this.getHelpList();
        },
        handleSizeChange(val){
            this.page_size = val;
            this.getHelpList();
        },
        clickSearch(){
            this.page_num = 1;
            this.getHelpList()
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            this.info.img = 'https://ukoo.oss-cn-beijing.aliyuncs.com/'+ res.data.url;
        },
        // 确定删除
        clickConfirm(){
            this.isDel = false
            let params = {
                id: this.rowInfo.id,
            }
            api.post('/service/system/daily_one_del', params, (res) =>  {
                this.$message.success('已删除')
                this.getHelpList()
            });
        },
        // 删除
        clickDel(row){
            this.isDel = true
            this.rowInfo = row
        },
        // 确定编辑
        clickSave(){
            if(this.info.content == ''){
                this.$message.error('请输入每日一言文案')
                return
            }
            if(this.info.img == ''){
                this.$message.error('请输入背景图片')
                return
            }
            this.isBigShow = false
            let params = {
                id: this.rowInfo.id,
                content: this.info.content,
                author: this.info.author,
                img: this.info.img,
            }
            api.post('/service/system/daily_one_edit', params, (res) =>  {
                this.$message.success('已修改')
                this.info.content = '';
                this.info.author = '';
                this.info.img = '';
                this.imageUrl = '';
                this.getHelpList()
            });
        },
        // 编辑
        clickEdit(row){
            this.rowInfo = row
            this.isBigShow = true
            this.info.content = row.content;
            this.info.author = row.author;
            this.info.img = row.img;
            this.imageUrl = row.img;
        },
        // 添加每日一言
        clickAddSave(){
            if(this.info.content == ''){
                this.$message.error('请输入每日一言文案')
                return
            }
            if(this.info.img == ''){
                this.$message.error('请输入背景图片')
                return
            }
            this.isBigShow = false
            let params = {
                content: this.info.content,
                author: this.info.author,
                img: this.info.img,
            }
            api.post('/service/system/daily_one_add', params, (res) =>  {
                this.$message.success('已添加')
                this.info.author = '';
                this.info.content = '';
                this.info.img = '';
                this.imageUrl = '';
                this.getHelpList()
            });
        },
        clickClose(){
            this.isBigShow = false
            this.info.author = '';
            this.info.content = '';
            this.miniId = '';
            this.info.img = '';
            this.imageUrl = '';
            this.rowInfo = {}
        },
        clickAdd(){
            this.rowInfo = {}
            this.isBigShow = true
        },
        // 获取文案列表
        getHelpList(){
            let params = {
                page_num: this.page_num,
                page_size: this.page_size,
                content: this.keyworld
            }
            api.post('/service/system/get_daily_one_list', params, (res) =>  {
                this.tableData = res.data.list
                this.total = res.data.count
            });
        },
    },
}
</script>

<style lang='scss'>
.setupDocument{
    .sm-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 20px 40px;
        .mt-title{
            font-size: 24px;
            color: #101010;
        }
        .mt-add{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .add{
                width: 130px;
                height: 36px;
                border-radius: 6px;
                border: 1px solid #0068ff;
                font-size: 14px;
                color: #0068ff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }
    .conetnt-wrap{
        padding: 20px;
        margin: 20px;
        background: #fff;
        position: relative;
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .class-wrap{
        .cw-title{
            font-size: 16px;
            color: #101010;
            font-weight: bold;
            margin-top: -5px;
            padding: 10px 20px;
        }
        .cw-list{
            padding: 30px 20px;
            .item{
                margin-bottom: 30px;
                .tip{
                    width: 120px;
                    font-size: 15px;
                    color: #101010;
                    padding-bottom: 10px;
                }
                .con{
                    width: calc(100% - 120px);
                }
                .el-textarea__inner{
                    height: 150px;
                }
                .avatar{
                    width: 100px;
                    height: 100px;
                    vertical-align: bottom;
                }
                .el-icon-plus{
                    font-size: 30px;
                    color: #c9c9c9;
                    width: 100px;
                    height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #FCFDFF;
                    border: 1px dashed #ddd;
                    border-radius: 10px;
                }
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 20px;
            .cw-btn{
                width: 100px;
                height: 40px;
                border-radius: 4px;
                font-size: 15px;
                color: #fff;
                background: #0068ff;
                margin: 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border: 1px solid #0068ff;
            }
            .quxiao{
                background: #fff;
                color: #0068ff;
            }
        }
    }
    .page-wrap{
        width: calc(100vw - 305px);
        z-index: 999;
        background: #fff;
        height: 60px;
        position: fixed;
        bottom: 0px;
        right: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 2px 5px 2px rgba(150, 150, 150, 0.1);
        .pagination-wrap{
            margin-left: 250px;
        }
    }
    .channel-wrap{
        padding: 0 80px;
        .cw-title{
            font-size: 15px;
            color: #101010;
            text-align: center;
            padding: 15px 0;
        }
        .cw-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            .btn{
                width: 100px;
                height: 35px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 30px 10px;
                border: 1px solid #0068ff;
                font-size: 15;
                color: #0068ff;
                cursor: pointer;
            }
            .confirm{
                background: #0068ff;
                color: #fff;
            }
        }
    }

}

</style>
